import { EnumParticipantFormKey, EnumParticipantSectionKey } from "@application/Enums/ParticipantEnum";
import { Card, CustomFieldsDisplayer } from "@key4-front-library/core/Bo/Components";
import type { DtoCustomFieldFormV01, DtoCustomFieldSubSectionV01, DtoParticipantGet } from "@key4-front-library/core/Dto";
import { Stack } from "@mui/material";

import ParticipationParticipantFields from "./ParticipationParticipantFields";
import { useOutletContext } from "react-router-dom";

interface ParticipantCustomTabContext {
	form?: DtoCustomFieldFormV01;
	formOrder: number;
	participant: DtoParticipantGet;
}

export const ParticipationForm = (): React.ReactNode => {
	const { form, formOrder, participant } = useOutletContext<ParticipantCustomTabContext>();
	const displayParticipantFields = (sectionKey: string) => form?.key === EnumParticipantFormKey.GENERAL && sectionKey === EnumParticipantSectionKey.MAIN;

	const formCount = formOrder >= 1 ? formOrder + 1 : formOrder;

	if (!form || formOrder === undefined || !participant) {
		return undefined;
	}

	const renderSubSection = (sectionOrder: number, subSection: DtoCustomFieldSubSectionV01, subSectionOrder: number) => {
		return (
			subSection.fields && (
				<Card key={subSection.key} title={subSection.label} sx={{ m: 0 }} boxShadow={false}>
					<>
						{subSection.fields.map((_, index) => (
							<Stack spacing={2} key={index}>
								{displayParticipantFields(subSection.key) && <ParticipationParticipantFields participant={participant} />}
								<CustomFieldsDisplayer
									formOrder={formCount}
									sectionOrder={sectionOrder}
									subSectionOrder={subSectionOrder}
									values={participant.customFieldValues}
								/>
							</Stack>
						))}
					</>
				</Card>
			)
		);
	};

	return (
		<Stack spacing={5}>
			{form.sections &&
				form.sections.map((section, sectionOrder) => (
					<Card key={section.key} title={section.label} sx={{ m: 0 }}>
						<>{section.subSections && section.subSections.map((subSection, subSectionOrder) => renderSubSection(sectionOrder, subSection, subSectionOrder))}</>
					</Card>
				))}
		</Stack>
	);
};
