import type { TabProps } from "@key4-front-library/core";
import { t } from "i18next";

export const participantsTabs = (): Array<TabProps> => [
	{
		label: t("old.registration.participantDetails.tabs2.mails"),
		to: "mail",
	},
	{
		label: t("old.registration.participantDetails.tabs2.faculty.title"),
		to: "faculty",
	},
];
