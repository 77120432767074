import { Icon, type TabProps } from "@key4-front-library/core";
import { t } from "i18next";

export const advancedSettingsTabs = (): Array<TabProps> => [
	{
		label: t("registration.advancedSettings.tabs.roles"),
		to: "roles",
		icon: <Icon name="tags" />,
	},
	{
		label: t("registration.advancedSettings.tabs.profiles"),
		to: "profiles",
		icon: <Icon name="file-user" />,
	},
	{
		label: t("eventSettings.emailing.title"),
		to: "emailing",
		icon: <Icon name="envelope" />,
	},
];
