import {
	faAddressCard,
	faArrowRight,
	faArrowsRotate,
	faArrowsSpin,
	faBasketShopping,
	faBell,
	faBoltLightning,
	faBomb,
	faBriefcase,
	faCalendarDays,
	faChalkboardUser,
	faChartLineUp,
	faCheck,
	faCircleChevronDown,
	faCircleInfo,
	faCirclePlus,
	faCircleUp,
	faCircleXmark,
	faClone,
	faDatabase,
	faEarthAmericas,
	faEllipsisVertical,
	faEnvelope,
	faFaceMask,
	faFileUser,
	faFloppyDisk,
	faGear,
	faGift,
	faGlobe,
	faHandPeace,
	faHandshakeAngle,
	faHeadset,
	faIdBadge,
	faLandmark,
	faMagnifyingGlass,
	faMicrophone,
	faMinus,
	faPencil,
	faPenNib,
	faPenToSquare,
	faPlus,
	faPlusLarge,
	faPodium,
	faScrewdriverWrench,
	faTableList,
	faTablePivot,
	faTags,
	faThumbtack,
	faTrash,
	faTrashCan,
	faUser,
	faUserDoctor,
	faUserGroup,
	faUserGroupCrown,
	faUserNurse,
	faUsers,
	faXmark,
} from "@fortawesome/pro-regular-svg-icons";

const RegularSet = {
	faAddressCard,
	faArrowRight,
	faArrowsRotate,
	faArrowsSpin,
	faBasketShopping,
	faBell,
	faBoltLightning,
	faBomb,
	faBriefcase,
	faCalendarDays,
	faChalkboardUser,
	faChartLineUp,
	faCheck,
	faCircleChevronDown,
	faCircleInfo,
	faCirclePlus,
	faCircleUp,
	faCircleXmark,
	faClone,
	faDatabase,
	faEarthAmericas,
	faEllipsisVertical,
	faEnvelope,
	faFaceMask,
	faFileUser,
	faFloppyDisk,
	faGear,
	faGift,
	faGlobe,
	faHandPeace,
	faHandshakeAngle,
	faHeadset,
	faIdBadge,
	faLandmark,
	faMagnifyingGlass,
	faMicrophone,
	faMinus,
	faPencil,
	faPenNib,
	faPenToSquare,
	faPlus,
	faPlusLarge,
	faPodium,
	faScrewdriverWrench,
	faTableList,
	faTablePivot,
	faTags,
	faThumbtack,
	faTrash,
	faTrashCan,
	faUser,
	faUserDoctor,
	faUserGroup,
	faUserGroupCrown,
	faUserNurse,
	faUsers,
	faXmark,
};

export default RegularSet;
