import ReactDOM from "react-dom/client";

import { Core, DefaultQueryClient } from "@key4-front-library/core";

import App from "./App";
import enProgramme from "./Translations/Languages/en.json";
import frProgramme from "./Translations/Languages/fr.json";

const rootElement = document.getElementById("root");

if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<Core queryClient={DefaultQueryClient} queryDebug={true} moduleTranslation={{ en: enProgramme, fr: frProgramme }}>
			<App />
		</Core>,
	);
}
