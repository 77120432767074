import { TabsLayout, Typography, useTabsRoute } from "@key4-front-library/core";
import { settingsTabs } from "@pages";
import { Outlet } from "react-router-dom";
import { t } from "i18next";

export const Settings = () => {
	const tabs = settingsTabs();
	const { active } = useTabsRoute(tabs.map((tab) => tab.to) ?? []);

	return (
		<TabsLayout
			title={{
				children: <Typography variant="h4">{t("registration.advancedSettings.title")}</Typography>,
			}}
			active={active}
			tabs={tabs}
		>
			<Outlet />
		</TabsLayout>
	);
};
