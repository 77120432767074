import "@application/Configurations/icons.configuration";

import { Settings } from "luxon";

import { Routes } from "@routes";
import { Licence } from "@key4-front-library/core";
import { DEFAULT_TIMEZONE } from "@key4-front-library/core/Constants/Date";
import DevToolsHelpers from "@key4-front-library/core/DevToolsHelpers";
import { LicenseInfo } from "@mui/x-license-pro";

import { version } from "../package.json";

LicenseInfo.setLicenseKey(Licence.mui);

const App = () => {
	// TODO: Remove when using the events' timezones
	Settings.defaultZone = DEFAULT_TIMEZONE;

	return (
		<>
			<DevToolsHelpers.VersionDevTools version={version} />
			<Routes />
		</>
	);
};

export default App;
