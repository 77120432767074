// TODO Move to '@key4-front-library/core

import { useState } from "react";

import type { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import K4Button, { type IK4ButtonProps } from "@infrastructure/components/interface/uikit/K4Button";
import { useIdentity } from "@key4-front-library/core";
import { alpha, Box, Fab, Grid, ListItemText, Menu, MenuItem, MenuList, Stack, Typography, useTheme } from "@mui/material";

interface TitleProps {
	title: string;
	emphasized?: string;
	buttons?: Array<IK4ButtonProps>;
	reference?: string | ReactJSXElement;
	fontSize?: string;
}

const Title = (props: TitleProps) => {
	const { title, emphasized, buttons, reference, fontSize = "34px" } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const identity = useIdentity();
	const isDarkMode = identity.darkmode;

	const theme = useTheme();

	const open = Boolean(anchorEl);
	const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenuItems = () => {
		setAnchorEl(null);
	};

	return (
		<Box style={{ paddingBottom: "18px", width: "100%" }} pt={5}>
			<Grid container alignItems="center" data-testid="title">
				<Grid item xs>
					<Stack direction={"row"} spacing={1.5} alignItems={"center"}>
						<Typography
							sx={{
								fontWeight: "Light",
								fontSize,
								color: isDarkMode ? theme.palette.common.white : alpha(theme.palette.common.black, 0.87),
							}}
						>
							{title}
							<Box component="span" sx={{ fontWeight: "Regular" }}>
								&nbsp;{emphasized}
							</Box>
						</Typography>
					</Stack>
				</Grid>

				{buttons && (
					<Grid item xs="auto" textAlign="end">
						{/* DESKTOP DISPLAY */}
						<Stack direction="row" spacing="10px" display={{ xs: "none", sm: "block" }}>
							{buttons.map((button, i) => {
								return (
									<K4Button
										key={i.toString()}
										label={button.label}
										color={button.color}
										handleClick={button.handleClick}
										startIcon={button.startIcon}
										endIcon={button.endIcon}
										variant={button.variant}
										disabled={button.disabled}
										size={button.size}
										sx={button.sx}
									/>
								);
							})}
						</Stack>

						{/* MOBILE DISPLAY */}
						<Box display={{ sm: "none" }}>
							{buttons.length > 1 ? (
								<Fab size="small" aria-label="actions" onClick={handleSettingsClick}>
									<Icon prefix="far" iconName="ellipsis-vertical" />
								</Fab>
							) : (
								<Fab size="small" aria-label="actions" color={buttons[0].color} onClick={buttons[0].handleClick}>
									<i className={`fal ${buttons[0].startIcon}`}></i>
								</Fab>
							)}

							<Menu
								id="settings-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleCloseMenuItems}
								MenuListProps={{
									"aria-labelledby": "button",
								}}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
							>
								<MenuList dense sx={{ width: "50vw" }}>
									{buttons.map((item, i) => {
										return (
											<MenuItem key={i} onClick={item.handleClick}>
												<ListItemText>
													<i className={`fal ${item.startIcon}`}></i> {item.label}
												</ListItemText>
											</MenuItem>
										);
									})}
								</MenuList>
							</Menu>
						</Box>
					</Grid>
				)}
			</Grid>

			{reference && (
				<Box sx={{ display: { xs: "none", sm: "block" } }}>
					<Typography variant={"h5"} fontWeight={"light"} color={"text.disabled"}>
						{reference}
					</Typography>
				</Box>
			)}
		</Box>
	);
};
export default Title;
