import { ErrorTemplate, getErrorTemplate } from "@key4-front-library/core";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

/**
 * ! Use only if client and event key is valid
 *
 * Same as ErrorTemplate but the "go back home" button redirect to current module
 */
export const ModuleErrorTemplate = () => {
	const { client = "", event = "" } = useParams();
	const theme = useTheme();

	return <ErrorTemplate to={`/${client}/${event}`} {...getErrorTemplate({ type: "404", options: { mode: theme.palette.mode } })} />;
};
